import React from 'react';
import Loadable from '@components/Loadable';
import { lazy } from 'react';
import CreateDepot from './ManageDepot/components/Create/CreateDepot';
import ManageFiles from './ManageFiles';

const Customer = Loadable(lazy(() => import('../AdminPage/ManageCustomer')));
const UserOperator = Loadable(lazy(() => import('../AdminPage/ManageUserOperator')));
const Role = Loadable(lazy(() => import('../AdminPage/ManageRole')));
const Partner = Loadable(lazy(() => import('../AdminPage/ManagePartner')));
const PartnerDetail = Loadable(lazy(() => import('../AdminPage/ManagePartner/components/detail')));
const Transaction = Loadable(lazy(() => import('../AdminPage/ManageTransaction')));
const TrasactionDetail = Loadable(
  lazy(() => import('../AdminPage/ManageTransaction/components/Detail'))
);
const Depot = Loadable(lazy(() => import('../AdminPage/ManageDepot')));
const Station = Loadable(lazy(() => import('../AdminPage/ManageStation')));
const DetailDepot = Loadable(lazy(() => import('../AdminPage/ManageDepot/components/detail')));
const DetailStation = Loadable(lazy(() => import('../AdminPage/ManageStation/components/detail')));

const adminRouters = [
  { path: '/admin/customer', element: <Customer /> },
  {
    path: '/admin/user',
    element: <UserOperator />
  },
  {
    path: '/admin/role',
    element: <Role />
  },
  {
    path: '/admin/partners',
    element: <Partner />
  },
  {
    path: 'admin/partners/detail',
    element: <PartnerDetail />
  },
  {
    path: 'admin/charging-session',
    element: <Transaction />
  },
  {
    path: 'admin/charging-session/:id/detail',
    element: <TrasactionDetail />
  },
  {
    path: '/admin/depot',
    element: <Depot />
  },
  {
    path: '/admin/depot/create',
    element: <CreateDepot />
  },
  {
    path: '/admin/depot/update/:depot_code',
    element: <CreateDepot />
  },
  {
    path: `/admin/depot/:depot_code/detail`,
    element: <DetailDepot />
  },
  {
    path: '/admin/station',
    element: <Station />
  },
  {
    path: `/admin/station/detail`,
    element: <DetailStation />
  },
  {
    path: '/admin/files',
    element: <ManageFiles />
  }
];

export default adminRouters;
