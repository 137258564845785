// React js
import React, { lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// components
import { ProtectedRoute } from '@components/ProtectedRoute';

// layouts
import DashboardLayout from '@layouts/dashboard/DashboardLayout';

// Pages
import NotFound from '@page/Page404';




// Router.
import materialRoutes from '@components/material-kit/MaterialRoutes';
import ChartsRoute from '@components/charts/ChartsRoute';
import authRoutes from '@page/Auth/AuthRoutes';
import customerRoutes from '@page/CustomerPage/CustomerRouters';
import adminRouters from '@page/AdminPage/AdminRoutes';
import accountingRouters from '@page/AccountingPage/AccountingRoutes';
import Loadable from '@components/Loadable';
const Depot = Loadable(lazy(() => import('@page/AdminPage/ManageDepot')));

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      element: (
        <ProtectedRoute>
          <DashboardLayout />
        </ProtectedRoute>
      ),
      children: [
        { path: '/account/dashboard', element: <Depot /> },
        ...customerRoutes,
        ...adminRouters,
        ...accountingRouters,
        ...materialRoutes,
        ...ChartsRoute
      ]
    },
    ...authRoutes,
    { path: '/', element: <Navigate to="account/dashboard" /> },
    { path: '*', element: <NotFound /> }
  ]);
}
