import React from 'react';
import get from 'lodash/get';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

function InputField(props) {
  const {
    name,
    label,
    required = false,
    formik,
    col,
    type = 'text',
    size = 'small',
    multiline = false,
    rows=4,
    rowsMax=4
  } = props;

  const _label = (
    <>
      {label} {required && type !== 'file' && <span style={{ color: 'red' }}>(*)</span>}
    </>
  );

  return (
    <Grid item md={col || 4} sm={12} sx={{ mt: 2 }}>
      <TextField
        {...props}
        required={false}
        fullWidth
        name={name}
        id={name}
        label={_label}
        onBlur={formik.handleBlur}
        value={get(formik.values, name)}
        onChange={formik.handleChange}
        error={Boolean(get(formik.errors, name) && get(formik.touched, name))}
        helperText={get(formik.touched, name) && get(formik.errors, name)}
        type={type}
        size={size}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
      />
    </Grid>
  );
}

export default InputField;
