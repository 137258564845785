import React from 'react';
import DataTable from '@components/DataTable';
import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import { compose } from '@reduxjs/toolkit';
import { isNull } from 'lodash';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import DialogUnbindUser from '../Dialogs/DialogsUnbinUser';

const UserTable = ({ items, depotCode, refetch }) => {
  const [users, setUsers] = React.useState(null);
  const [openUnbin, setOpenUnbin] = React.useState(false);
  const { t } = useTranslation(['admin']);

  const handleOpenDialogUnbindUser = (d) => {
    setUsers(d);
    setOpenUnbin(true);
  };

  const getColumns = () => {
    return [
      { accessor: 'userId', Header: 'Mã khách hàng' },
      { accessor: 'userName', Header: 'Tài khoản' },
      { accessor: 'name', Header: 'Tên khách hàng' },
      { accessor: 'email', Header: 'Email' },
      {
        accessor: (d) => {
          return (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                handleOpenDialogUnbindUser(d);
              }}>
              <CandlestickChartIcon />
            </Link>
          );
        },
        Header: 'Gỡ khách hàng'
      }
    ];
  };

  return (
    <>
      <DialogUnbindUser
        depotCode={depotCode}
        users={users}
        openUnbin={openUnbin}
        setOpenUnbin={setOpenUnbin}
        refetch={refetch}
      />
      <Box width="100%" overflow="auto" style={{ marginTop: '10px' }}>
        <DataTable
          style={{ marginTop: '10px' }}
          columns={getColumns()}
          data={items || []}
          tableName="Danh sách khách hàng gắn với trạm sạc"
          pagination={false}
        />
      </Box>
    </>
  );
};

export default compose(withPaginate)(UserTable);
