import React from 'react';
import DataTable from '@components/DataTable';
import { Box, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withPaginate } from '@contexts/Paginate/PaginateContext';
import { compose } from '@reduxjs/toolkit';
import DialogUnbinStation from '@page/AdminPage/ManageDepot/components/Dialogs/DialogsUnbinStation';

const StationTable = ({ items, depotCode, refetch }) => {
  const [open, setOpen] = React.useState(false);
  const [station, setStation] = React.useState(null);
  const [openUnbin, setOpenUnbin] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(['admin']);
  const redirectStationDetail = (stationId) => {
    navigate(`/admin/station/detail`, { state: { id: stationId } });
  };
  const redirectChargingSessionDetail = (identifier) => {
    navigate(`/admin/charging-session`, { state: { station_identifier: identifier } });
  };

  const handleOpenDialogUnbindStation = (d) => {
    setStation(d);
    setOpenUnbin(true);
  };

  const getColumns = () => {
    return [
      {
        accessor: (d) => {
          return (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                redirectStationDetail(d.id);
              }}>
              {d.identifier}
            </Link>
          );
        },
        Header: 'Mã trụ'
      },
      { accessor: 'model', Header: 'Model' },
      { accessor: 'vendor', Header: 'Hãng sản xuất' },
      { accessor: 'serial_number', Header: 'Serial number' },
      { accessor: 'address', Header: 'Địa chỉ' },
      {
        accessor: (d) => {
          return (
            <>
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  handleOpenDialogUnbindStation(d);
                }}>
                Gỡ trụ sạc
              </Link>
              {' '}/{' '}
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  redirectChargingSessionDetail(d.identifier);
                }}>
                Xem chi tiết phiên sạc
              </Link>
            </>
          );
        },
        Header: 'Hành động'
      }
    ];
  };

  return (
    <>
      <DialogUnbinStation
        depotCode={depotCode}
        station={station}
        openUnbin={openUnbin}
        setOpenUnbin={setOpenUnbin}
        refetch={refetch}
      />
      <Box width="100%" overflow="auto" style={{ marginTop: '10px' }}>
        <DataTable
          style={{ marginTop: '10px' }}
          columns={getColumns()}
          data={items || []}
          tableName="Danh sạch trụ sạc gắn với trạm sạc"
          pagination={false}
        />
      </Box>
    </>
  );
};

export default compose(withPaginate)(StationTable);
