import React from 'react';

import Button from '@mui/material/Button';
import DialogCustomerTag from './RequestDialog';
import { AddCircleOutline } from '@mui/icons-material';
const RequestButton = ({ refetch, masterData }) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    refetch();
    setOpen(false);
  };
  const handleOpenDialog = () => {
    setOpen(true);
  };
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        sx={{
          color: '#FFFFFF',
          fontSize: '14px',
          fontWeight: 600,
          bgcolor: '#2D9764',
          '&:hover': {
            bgcolor: '#2D9764'
          }
        }}
        onClick={handleOpenDialog}>
        Tạo mới
      </Button>
      <DialogCustomerTag open={open} onClose={onClose} masterData={masterData}/>
    </>
  );
};

export default RequestButton;
