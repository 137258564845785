import React, { useEffect, useState } from 'react';
import HANOI_CENTER from '@contants/la_center';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { CircularProgress, Link } from '@mui/material';
import Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function DashboardLeafletMap() {
  const styles = {
    main: {
      width: '100%',
      height: '100%'
    },
    inputText: {
      padding: '10px',
      color: 'red'
    }
  };
  let { data, isLoading, refetch, error, isSuccess } = useQuery(['admin-all-depots'], () =>
    rootApi.get('/admin/depots/search?page=0&size=9999')
  );
  const [places, setPlaces] = useState([]);
  const position = [16.994831, 106.965091];
  const zoomLevel = 6;

  useEffect(() => {
    const dataMap = data?.data?.data?.map((dp) => {
      return {
        id: dp?.id || '',
        name: dp?.depot_name || '',
        code: dp?.depot_code || '',
        address: dp?.address || '',
        lat: dp?.latitude || '',
        lng: dp?.longitude || '',
        partner_id: dp?.partner_id || '',
        show: false,
        depot: true
      };
    });
    setPlaces(dataMap);
  }, [data]);

  function genIcon(partner_id) {
    let iconUrl = '/assets/images/icon/location_evc.png';
    if (partner_id === 1) {
      iconUrl = '/assets/images/icon/location_evc.png';
    } else if (partner_id === 2) {
      iconUrl = '/assets/images/icon/location_evc.png';
    }
    return new Leaflet.Icon({
      iconUrl: iconUrl,
      iconAnchor: [5, 45],
      popupAnchor: [10, -44],
      iconSize: new Leaflet.Point(45, 45)
    });
  }

  const vinfastIcon = genIcon(2);
  const everevIcon = genIcon(1);
  const defaultIcon = genIcon(0);

  const getIcon = (partner_id) => {
    if (partner_id === 1) {
      console.log('icon everev');
      return everevIcon;
    } else if (partner_id === 2) return vinfastIcon;
    else return defaultIcon;
  };

  const redirectPageDetail = (depot_code) => {
    if (!depot_code) {
      return;
    }
    window.open(`${window.location.origin}/admin/depot/${depot_code}/detail`, '_blank');
  };

  return (
    <>
      <MapContainer
        style={styles.main}
        zoom={zoomLevel}
        center={HANOI_CENTER}
        scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {places?.map((place) => (
          <Marker position={[place.lat, place.lng]} key={place.id} icon={getIcon(place.partner_id)}>
            <Popup>
              <b>Tên trạm:</b> {place.name}
              <br />
              <b>Mã trạm: </b>
              <Link
                style={{ textAlign: 'left' }}
                component="button"
                variant="body2"
                onClick={() => {
                  redirectPageDetail(place?.code);
                }}>
                {' ' + place?.code}
              </Link>
              <br />
              <p>
                <b>Địa chỉ:</b> {place.address}
              </p>
              <b>Lat:</b> {place.lat}
              <br />
              <b>Lng:</b> {place.lng}
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      {isLoading && <CircularProgress size={20} />}
    </>
  );
}
