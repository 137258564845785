import { useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { URL_API } from '@configs/';
import { contantAuthentication } from '@contants/';
import { loadFromLocalStorageObjectFromBase64 } from '@databases/localStorage';

export const getListProvinces = () => {
  const { data, isLoading } = useQuery(['admin-get-all-provinces'], () =>
    rootApi.get('/areas/get-by-parent')
  );
  return { data: data?.data?.data || [], isLoading };
};

export const getDataByParentCode = async (code) => {
  let user = loadFromLocalStorageObjectFromBase64(contantAuthentication.DATA_AUTH);
  if (!isEmpty(user)) {
    user = JSON.parse(user);
  }
  const resp = await axios
    .get(`${URL_API}/areas/get-by-parent?parent_code=${code}`, {
      headers: {
        Authorization: `Bearer ${user?.access_token}`
      }
    })
    .then(function (response) {
      return response?.data?.data;
    });
  return resp;
};

export const getListOperators = () => {
  const { data, isLoading: loadingUser } = useQuery(['admin-get-user-operator'], () =>
    rootApi.get('/admin/users/operator?page=0&size=999')
  );
  return { data: data?.data?.data || [], loadingUser };
};
