import moment from 'moment';

export const formatDateToString = (date, patten = 'DD-MM-YYYY HH:mm') =>
  date ? moment(date).format(patten).toString() : '';

export const convertLocaltimeToUTC = (date, hour) => {
  let result = null;
  try {
    if (date) {
      const localDateTimeMoment = moment(
        `${moment(new Date(date)).format('YYYY-MM-DD')} ${hour}`,
        'YYYY-MM-DD HH:mm:ss.SSS'
      );

      const utcDateTimeMoment = localDateTimeMoment.utc();

      result = utcDateTimeMoment.toISOString();
    }
  } catch (e) {
    console.log('Exception at convertLocaltimeToUTC');
    console.log(e);
  }

  return result;
};


export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === '';
}

export function formatCurrency(money, digits) {
  if (isNullOrEmpty(money)) {
    return '0';
  }
  let format = '$1.';
  return `${digits ? money : Math.trunc(+money)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, format);
}