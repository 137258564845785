import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

const ImageDropZone = ({handleChangeImage}) => {
  // Specify the URL where you want to handle file uploads
  const uploadUrl = 'YOUR_UPLOAD_ENDPOINT';

  const getUploadParams = ({ file, meta }) => {
    return { url: uploadUrl };
  };

  const handleChange = ({ meta, file }, status) => {
    handleChangeImage(file, status);
  };

  return (
    <Dropzone
      maxFiles={1}
      multiple={false}
      inputContent={`Thả tệp tin ở đây hoặc nhấp để tải lên`}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChange}
      accept="image/*"
      submitButtonDisabled={true}
      canRestart={false}
      styles={{
        dropzone: { minHeight: 200, minWidth: 200, overflow: 'hidden' },
        dropzoneActive: { borderColor: 'green' }
      }}
    />
  );
};

export default ImageDropZone;
