import React, { useEffect } from 'react';

import { Icon, Grid, Divider } from '@mui/material';
import { Span } from '@components/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const FormField = ({
  onCloseDialog,
  children,
  formik,
  isCreating,
  isSuccess,
  actionName,
  isLoading,
  icon = 'add-circle-outline',
  showIcon = true
}) => {
  // useEffect(() => {
  //   if (isSuccess) {
  //     formik?.resetForm();
  //     onCloseDialog();
  //   }
  // }, [isSuccess]);

  const onClose = () => {
    formik?.resetForm();
    onCloseDialog();
  };

  return (
    <form onSubmit={formik?.handleSubmit}>
      <Grid container>{children}</Grid>
      <Box py={2} sx={{ display: 'float', float: 'right', paddingTop: 5 }}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="inherit"
          sx={{ borderColor: '#E4E4E7', borderWidth: '1px' }}>
          {showIcon && <Icon>clear</Icon>}
          <Span sx={{ pr: 1, textTransform: 'capitalize' }}>Đóng</Span>
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            ml: 1,
            color: '#FFFFFF',
            fontSize: '14px',
            fontWeight: 600,
            bgcolor: '#2D9764',
            '&:hover': {
              bgcolor: '#2D9764'
            }
          }}
          loading={isLoading}>
          {isCreating && <CircularProgress size={10} />}
          {showIcon && <Icon>{icon}</Icon>}
          <Span sx={{ pl: 1, textTransform: 'capitalize' }}>{actionName}</Span>
        </Button>
      </Box>
    </form>
  );
};

export default FormField;
