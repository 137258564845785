import React, { useEffect } from 'react';
import get from 'lodash/get';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from 'lodash/isEmpty';

function SelectField(props) {
  const { name, label, required, formik, col, options, loading, multiple } = props;

  const _label = (
    <>
      {label} {required && <span style={{ color: 'red' }}>(*)</span>}
    </>
  );

  // useEffect(() => {
  //   if (options.length > 0) {
  //     formik.setFieldValue(name, options[0]);
  //   }
  // }, [options.length]);

  return (
    <Grid item md={col || 4} sm={12} sx={{ mt: 2 }}>
      <Autocomplete
        {...props}
        multiple={multiple}
        value={multiple && isEmpty(get(formik.values, name)) ? [] : get(formik.values, name)}
        onChange={(_, val) => {
          formik.setFieldValue(name, val || [], false);
        }}
        options={options}
        loading={loading}
        isOptionEqualToValue={(option, value) => option?.label === value?.label}
        getOptionLabel={(option) => option?.label}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
            name={name}
            error={Boolean(get(formik.errors, name) && get(formik.touched, name))}
            helperText={get(formik.touched, name) && get(formik.errors, name)}
            onBlur={formik.handleBlur}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
          />
        )}
      />
    </Grid>
  );
}

export default SelectField;
