import {
  loadFromLocalStorage,
  saveToLocalStorage,
  saveToLocalStorageObject
} from '@databases/localStorage';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { contantAuthentication } from '@contants/index';
import { showError } from '@components/notification';

// Context.
import { AuthContext } from './Authcontext';

// Service.
import { signIn, resetPassword, Registered, getUserInfo } from '@page/Auth/requests';

const AuthProvider = (props) => {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = React.useState(false);

  const [token, setToken] = React.useState(loadFromLocalStorage(contantAuthentication.DATA_AUTH));

  React.useEffect(() => {
    if (isLogin) {
      const origin = location.state?.from?.pathname || '/account/dashboard';
      navigate(origin);
    }
  }, [isLogin]);

  const handleLogin = async (username, password) => {
    const response = await signIn(username, password);
    if ([200, 201].includes(response?.status)) {
      if (response?.data.status.code !== 'success') {
        showError(response?.data.status.message);
      } else {
        const tokenUser = JSON.stringify(response?.data.data);
        setToken(tokenUser);
        saveToLocalStorage(contantAuthentication.DATA_AUTH, tokenUser);
        // load userInfo
        const { data } = await getUserInfo();
        const userInfo = data?.data;
        saveToLocalStorageObject('userInfo', userInfo);
        saveToLocalStorage('roles', JSON.stringify(userInfo?.roles));

        setIsLogin(true);
        navigate('/account/dashboard');
      }
    } else {
      showError('Có lỗi xảy ra, vui lòng liên hệ ban quản trị!');
    }
  };

  const handleLogout = async () => {
    setToken('');
    saveToLocalStorage(contantAuthentication.DATA_AUTH, '');
    setIsLogin(false);
    navigate('/account/signin');
  };

  const handleResetPassword = async () => {
    const response = await resetPassword();
    if ([200, 201].includes(response?.status)) {
      navigate('/account/signin');
    } else {
      showError('Có lỗi xảy ra, vui lòng liên hệ ban quản trị!');
    }
  };

  const handleRegister = async (values) => {
    const response = await Registered(values);
    if ([200, 201].includes(response?.status)) {
      navigate('/account/registered');
    } else {
      showError('Có lỗi xảy ra, vui lòng liên hệ ban quản trị!');
    }
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
    resetPassword: handleResetPassword,
    register: handleRegister
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
