import contantAction from './action';
import contantInputType from './inputType';
import contantFormatDay from './formatDayTime';
import contantAuthentication from './authentication';
import contantPagination from './pagination';

export const LANG_OPTIONS = {
  VI: 'vi',
  EN: 'en'
};

export const DEFAULT_LANG = 'vi';
export {
  contantAction,
  contantInputType,
  contantFormatDay,
  contantAuthentication,
  contantPagination
};
export const DATE_TIME_FORMAT_BY_LANG = {
  [LANG_OPTIONS.VI]: 'dd/MM/yyyy HH:mm:ss',
  [LANG_OPTIONS.EN]: 'MMM dd, yyyy HH:mm:ss'
};

export const DATE_FORMAT_BY_LANG = {
  [LANG_OPTIONS.VI]: 'dd/MM/yyyy',
  [LANG_OPTIONS.EN]: 'MMM dd, yyyy'
};
export const MODAL_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DETAIL: 'DETAIL',
  UPDATE_HEADER: 'UPDATE_HEADER'
};
