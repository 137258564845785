import React, { useState } from 'react';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputField from '@components/FormControls/InputField';
import DialogFormField from '@components/FormControls/DialogFormField';
import { useMutation, useQuery } from 'react-query';
import rootApi from '@utils/baseAPI/WrapperApi';
import { showError, showSuccess, showWarning } from '@components/notification';
import * as Yup from 'yup';
import SelectBox from '@components/SelectBox';
import { Box, Divider, Grid, IconButton, Input, LinearProgress, Typography } from '@mui/material';
import { CloudUpload, CloudUploadOutlined } from '@mui/icons-material';
import { uploadFile } from '@utils/utils';
import { optionChargingType, optionServerType, optionVendor } from '@utils/constant';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{ height: 7, borderRadius: 5 }} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const DialogsEdit = (props) => {
  const { tag, open, setOpen, refetch, masterData } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [processUpload, setProcessUpload] = useState(0);
  const [onUpload, setOnUpload] = useState(false);
  const [loading, setLoading] = useState(false);

  const valueMasterData = masterData ? JSON.parse(masterData?.value) : '';
  const optionVendor = valueMasterData
    ? valueMasterData?.vendor?.map((item) => ({
        label: item,
        value: item
      }))
    : [];
  const optionChargingType = valueMasterData
    ? valueMasterData?.station_type?.map((item) => ({
        label: item,
        value: item
      }))
    : [];
  const optionPower = valueMasterData
    ? valueMasterData?.power?.map((item) => ({
        label: item,
        value: item?.toLowerCase()?.replace(/ kw/, '')
      }))
    : [];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  React.useEffect(() => {
    if (open) {
      formik.setFieldValue('file_name', tag?.filename, false);
      formik.setFieldValue('description', tag?.description, false);
      formik.setFieldValue('url', tag?.url, false);
      formik.setFieldValue('vendor', tag?.vendor, false);
      formik.setFieldValue('version', tag?.version, false);
      formik.setFieldValue('power', tag?.power, false);
      formik.setFieldValue('charging_type', tag?.charging_type, false);
      formik.setFieldValue('server_type', tag?.server_type, false);
      setSelectedFile('');
      setProcessUpload('');
    }
  }, [tag?.id, open]);

  const { mutateAsync, isLoading, isSuccess } = useMutation('update-file-upload', (data) => {
    return rootApi.put(`/files/update/${tag?.id}`, {
      filename: data.file_name,
      description: data?.description,
      url: data?.url,
      vendor: data?.vendor,
      version: data?.version,
      power: data?.power,
      model: data?.model,
      charging_type: data?.charging_type,
      sever_type: data?.version?.sever_type
    });
  });
  const formik = useFormik({
    initialValues: {
      filename: tag?.filename ?? '',
      description: tag?.description ?? '',
      url: tag?.url,
      vendor: tag?.vendor,
      version: tag?.version,
      power: tag?.power,
      model: tag?.model,
      charging_type: tag?.charging_type,
      sever_type: tag?.version?.sever_type
    },
    validationSchema: Yup.object({
      file_name: Yup.string().required().label('Tên file').nullable(),
      vendor: Yup.string().required().label('Nhà cung cấp').nullable(),
      version: Yup.string().required().label('Phiên bản').nullable(),
      charging_type: Yup.string().required().label('Loại sạc').nullable(),
      server_type: Yup.string().required().label('Giao thức').nullable(),
      power: Yup.string().required().label('Công suất').nullable()
    }),
    onSubmit: async (values) => {
      if (!selectedFile) {
        showWarning({ message: 'Vui lòng chọn file!' });
        return;
      }
      setLoading(true);
      setOnUpload(true);
      const params = {
        vendor: values?.vendor,
        file_name: values?.file_name,
        version: values?.version,
        server_type: values?.server_type
      };
      const res = await uploadFile(selectedFile, (number) => setProcessUpload(number), params);
      console.log(res?.data?.data);
      formik.setFieldValue('url', res?.data?.data, false);
      mutateAsync({ ...values, url: res?.data?.data })
        .then(() => {
          handleClose();
          showSuccess({ message: 'Cập nhật file thành công' });
          formik.resetForm();
        })
        .catch((e) => {
          return showError('Cập nhật thất bại!');
        })
        .finally(() => setLoading(false));
    }
  });
  const handleClose = () => {
    refetch();
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Chỉnh sửa thông tin</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogFormField
            onCloseDialog={handleClose}
            formik={formik}
            isLoading={loading}
            isSuccess={isSuccess}
            showIcon={false}
            actionName="Chỉnh sửa">
            <Grid container spacing={1}>
              <Grid item md={6} sm={12}>
                <SelectBox
                  getOptionLabel={(option) => option.label || ''}
                  options={optionVendor}
                  name="vendor"
                  label={'Nhà cung cấp'}
                  size="small"
                  col={12}
                  handleBlur={formik.handleBlur}
                  hasError={formik.touched.vendor && Boolean(formik.errors.vendor)}
                  errorText={formik.touched.vendor && formik.errors.vendor}
                  value={optionVendor?.find((item) => item.value === formik.values?.vendor)}
                  onChange={(_, option) => {
                    formik.setFieldValue('vendor', option?.value);
                  }}
                  sx={{ marginTop: 2 }}
                  required
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <SelectBox
                  getOptionLabel={(option) => option.label || ''}
                  options={optionChargingType}
                  name="charging_type"
                  label={'Loại trụ'}
                  size="small"
                  col={12}
                  handleBlur={formik.handleBlur}
                  value={optionChargingType?.find(
                    (item) => item.value === formik.values?.charging_type
                  )}
                  onChange={(_, option) => {
                    formik.setFieldValue('charging_type', option?.value);
                  }}
                  sx={{ marginTop: 2 }}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <SelectBox
                  getOptionLabel={(option) => option.label || ''}
                  options={optionPower}
                  name="power"
                  label={'Công suất'}
                  size="small"
                  col={12}
                  handleBlur={formik.handleBlur}
                  value={optionPower?.find((item) => item.value === formik.values?.power)}
                  hasError={formik.touched.power && Boolean(formik.errors.power)}
                  errorText={formik.touched.power && formik.errors.power}
                  onChange={(_, option) => {
                    formik.setFieldValue('power', option?.value);
                  }}
                  sx={{ marginTop: 2 }}
                  required
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <InputField
                  formik={formik}
                  name="version"
                  value={formik.values.version}
                  onBlur={formik.handleBlur}
                  error={formik.touched.version && Boolean(formik.errors.version)}
                  helperText={formik.touched.version && formik.errors.version}
                  label="Phiên bản"
                  col={12}
                  required
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <InputField
                  formik={formik}
                  name="file_name"
                  value={formik.values.file_name}
                  onBlur={formik.handleBlur}
                  error={formik.touched.file_name && Boolean(formik.errors.file_name)}
                  helperText={formik.touched.file_name && formik.errors.file_name}
                  required
                  label="Tên file"
                  col={12}
                />
              </Grid>

              {/* <Grid item md={6} sm={12} mt={1}>
                <InputField
                  formik={formik}
                  name="model"
                  value={formik.values.model}
                  onBlur={formik.handleBlur}
                  label="Model"
                  col={12}
                />
              </Grid> */}

              <Grid item md={6} sm={12}>
                <SelectBox
                  getOptionLabel={(option) => option.label || ''}
                  options={optionServerType}
                  name="server_type"
                  label={'Giao thức'}
                  size="small"
                  col={12}
                  handleBlur={formik.handleBlur}
                  hasError={formik.touched.server_type && Boolean(formik.errors.server_type)}
                  errorText={formik.touched.server_type && formik.errors.server_type}
                  value={optionServerType?.find(
                    (item) => item.value === formik.values?.server_type
                  )}
                  onChange={(_, option) => {
                    formik.setFieldValue('server_type', option?.value);
                  }}
                  sx={{ marginTop: 2 }}
                  required
                />
              </Grid>
              <Grid item md={12} sm={12}>
                <InputField
                  formik={formik}
                  name="description"
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  label="Mô tả"
                  col={12}
                  multiline
                  rows={3}
                  rowsMax={5}
                />
              </Grid>
            </Grid>
            <label
              htmlFor="upload-button"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px dashed #aaa',
                padding: '10px',
                borderRadius: '5px',
                width: '100%',
                marginTop: '20px',
                cursor: 'pointer'
              }}>
              <Input
                type="file"
                onChange={handleFileChange}
                sx={{ width: '100%', marginTop: '5px' }}
                style={{ display: 'none' }}
                id="upload-button"
              />
              <IconButton color="primary" aria-label="upload picture" component="span">
                <CloudUploadOutlined style={{ color: '#2D9764' }} />
              </IconButton>
              <Typography variant="body1" component="span">
                {selectedFile ? selectedFile.name : 'Nhấp vào khu vực này để tải lên file'}
              </Typography>
              <Box sx={{ width: '100%' }}>
                <>{processUpload != 0 && <LinearProgressWithLabel value={processUpload} />}</>
              </Box>
            </label>
          </DialogFormField>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default DialogsEdit;
