import React from 'react';
import SelectField from '@components/FormControls/SelectField';
import { useFormik } from 'formik';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useMutation, useQuery } from 'react-query';
import DialogFormField from '@components/FormControls/DialogFormField';
import { showError, showSuccess } from '@components/notification';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DialogBindOwner = (props) => {
  const { depot, open, setOpen, refetch } = props;
  const dataCustomer = useQuery(['admin-get-user-operators'], () =>
    rootApi.get(`/admin/users/customer?page=0&size=9999999`)
  );

  const users = dataCustomer?.data?.data.data || [];
  const userOptions = users?.map((item) => ({
    label: `${item?.username} - ${item?.full_name}`,
    value: item?.id
  }));
  const handleChangeCustomer = (event, selectedValue) => {
    formik.setFieldValue('user_ids', selectedValue);
  };

  const { mutateAsync, isLoading, isSuccess } = useMutation(['bind-users'], (data) => {
    const user_ids = data?.user_ids?.map((item) => item.value);
    rootApi.post(`/depot-users/bind`, {
      depot_code: depot?.depot_code,
      user_ids: user_ids
    });
  });

  const formik = useFormik({
    initialValues: {
      user_ids: []
    },
    onSubmit: (values) => {
      mutateAsync(values)
        .then(() => {
          handleClose();
          showSuccess({ message: 'Gắn khách hàng trạm thành công' });
        })
        .catch((error) => {
          showError(error || 'Vui lòng liên hệ admin để được hỗ trợ');
        });
    }
  });

  const handleClose = () => {
    formik.resetForm();
    refetch();
    setOpen(false);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>Gán khách hàng vào trạm sạc</DialogTitle>
        <DialogContent>
          <DialogFormField
            onCloseDialog={handleClose}
            isSuccess={isSuccess}
            isLoading={isLoading}
            formik={formik}
            icon="save"
            actionName="Lưu">
            <SelectField
              multiple
              formik={formik}
              options={userOptions}
              value={formik.values.user_ids}
              getOptionLabel
              required
              onChange={handleChangeCustomer}
              name="user_ids"
              label="Chọn khách hàng"
              col={12}
            />
          </DialogFormField>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DialogBindOwner;
