import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const styles = {
  main: {
    width: '100%',
    height: '100%'
  },
  inputText: {
    padding: '10px',
    color: 'red'
  }
};
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`;
const LeafletMap = ({ children, ...props }) => (
  <Wrapper>
    <MapContainer style={styles.main} scrollWheelZoom={true} {...props}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  </Wrapper>
);
LeafletMap.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

LeafletMap.defaultProps = {
  children: null
};

export default LeafletMap;
