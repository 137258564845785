export const topBarHeight = 64;
export const sideNavWidth = 280;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;
export const dateFormat = 'YYYY-MM-DD';

export const optionVendor = [
  {
    label: 'Juhang',
    value: 'Juhang'
  },
  {
    label: 'Tary',
    value: 'Tary'
  },
  {
    label: 'StarCharge',
    value: 'StarCharge'
  },
  {
    label: 'MaxPower',
    value: 'MaxPower'
  },
  {
    label: 'Meidian',
    value: 'Meidian'
  },
  {
    label: 'Beny',
    value: 'Beny'
  }
];

export const optionChargingType = [
  {
    label: 'AC',
    value: 'ac'
  },
  {
    label: 'DC',
    value: 'dc'
  }
];

export const optionServerType = [
  {
    label: 'HTTP',
    value: 'HTTP'
  },
  {
    label: 'FTP',
    value: 'FTP'
  }
];
