import React from 'react';

// Router
import Router from './routes';

// Component
import AuthProvider from '@contexts/Auth/AuthProvider';
import ScrollToTop from '@components/ScrollToTop';
import { SettingsProvider } from '@contexts/Setting/SettingsContext';
import Theme from '@theme/Theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import i18n from '@utils/i18n';
import { I18nextProvider } from 'react-i18next';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 0
    }
  }
});

function App() {
  return (
    <AuthProvider>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <SettingsProvider>
            <Theme>
              <ReactNotifications />
              <Router />
            </Theme>
          </SettingsProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </AuthProvider>
  );
}

export default App;
