import { ROLES } from '@contants/roles.constant';
import { loadFromLocalStorageObjectFromBase64 } from '@databases/localStorage';

function usePermission() {
  const roles = JSON.parse(loadFromLocalStorageObjectFromBase64('roles'));

  const adminRoles = roles.includes(ROLES.ADMIN);

  return {
    adminRoles
  };
}

export default usePermission;
