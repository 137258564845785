import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import rootApi from '@utils/baseAPI/WrapperApi';
import { useMutation, useQuery } from 'react-query';
import Page from '@components/LayoutPage';
import ActionBar from '@components/ActionBar';
import StationTable from '../form/StationTable';
import { get, isEmpty, isNull } from 'lodash';
import Button from '@components/Button';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import DialogBindOwner from '../Dialogs/DialogBindOwner';
import UserTable from '../form/UserTable';
import { LoadingButton, TabContext, TabPanel } from '@mui/lab';
import { Formik } from 'formik';
import * as yup from 'yup';
import { showError, showSuccess } from '@components/notification';
import SelectBox from '@components/SelectBox';
import { getDataByParentCode, getListOperators, getListProvinces } from '@page/AdminPage/utils';
import ImageDropZone from './DropZone';
import { depotType } from '../constant';
import LeafletMap from '@components/Map/LeafletMap';
import { Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import Leaflet from 'leaflet';
import axios from 'axios';
import { loadFromLocalStorageObjectFromBase64 } from '@databases/localStorage';
import { URL_API } from '@configs/index';
import { contantAuthentication } from '@contants/index';
import usePermission from '@hooks/usePermission';

const CreateDepot = () => {
  const param = useParams();
  const navigate = useNavigate();
  const [openDialogBlindOwner, setOpenDialogBlindOwner] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [valueTab, setValueTab] = useState('detailDepot');
  const [districtOptions, setDistrictOptions] = useState([]);
  const [communeOptions, setCommuneOptions] = useState([]);
  const [pro, setPro] = useState({});
  const [dist, setDist] = useState({});
  const [comm, setComm] = useState({});
  const [chargeType, setChargeType] = useState({});
  const [partner, setPartner] = useState('');
  const { adminRoles } = usePermission();
  const [userOperator, setUserOperator] = useState('');
  const { t } = useTranslation(['admin']);
  const [depot, setDepot] = useState(null);
  const [initialValues, setInitialValue] = useState({
    depot_code: '',
    depot_name: '',
    address: '',
    latitude: 21.0526986,
    longitude: 105.7781333,
    partner_id: '',
    charge_type: 'PUBLIC_CHARGING',
    depot_pic: '',
    province: '',
    district: '',
    commune: ''
  });

  const loadDistricts = async (code) => {
    const distOptions = await getDataByParentCode(code);
    setDistrictOptions(
      distOptions?.map((element) => {
        return { label: String(element.name), value: String(element.code) };
      })
    );
  };
  const loadCommunes = async (code) => {
    const commOptions = await getDataByParentCode(code);
    setCommuneOptions(
      commOptions?.map((element) => {
        return { label: String(element.name), value: String(element.code) };
      })
    );
  };

  const fetchDepotDetail = async () => {
    try {
      const data = await rootApi.get(`/admin/depots/${param?.depot_code}/get-one`);
      const depotDetail = data?.data?.data;
      setDepot(depotDetail);
      // get dis
      loadDistricts(depotDetail?.province_code);
      loadCommunes(depotDetail?.district_code);

      const depotPic = { label: depotDetail?.depot_pic, value: depotDetail?.depot_pic_id };
      setUserOperator(depotPic);
      const pro = {
        label: String(depotDetail?.province),
        value: String(depotDetail?.province_code)
      };
      setPro(pro);
      const dist = {
        label: String(depotDetail?.district),
        value: String(depotDetail?.district_code)
      };
      setDist(dist);
      const comm = {
        label: String(depotDetail?.commune),
        value: String(depotDetail?.commune_code)
      };
      setComm(comm);
      const existPartner = partner_options.find((item) => item.value === depotDetail?.partner_id);
      setPartner(existPartner);
      const existChargeType = depotType.find((item) => item.value === depotDetail?.charge_type);
      setChargeType(existChargeType);

      setInitialValue({
        depot_code: depotDetail?.depot_code ?? '',
        depot_name: depotDetail?.depot_name ?? '',
        address: depotDetail?.address ?? '',
        latitude: depotDetail?.latitude ?? '',
        longitude: depotDetail?.longitude ?? '',
        partner_id: depotDetail?.partner_id ?? '',
        charge_type: depotDetail?.charge_type ?? '',
        depot_pic: depotDetail?.depot_pic_id ?? '',
        province: depotDetail?.province_code ?? '',
        district: depotDetail?.district_code ?? '',
        commune: depotDetail?.commune_code ?? '',
        image_url: depotDetail?.image_url ?? ''
      });
    } catch {
      showError('Lấy thông tin trạm sạc thất bại!');
    }
  };

  useEffect(() => {
    if (param?.depot_code) {
      fetchDepotDetail();
    }
  }, [param?.depot_code]);

  const [isNewImage, setIsNewImage] = useState(!isNull(depot?.image_url));
  const backToList = () => {
    navigate('/admin/depot');
  };
  const handleChangeTab = (e, value) => {
    setValueTab(value);
  };

  const getPartnerOptions = () => {
    const { data: partners } = useQuery(['get-partners-list'], () =>
      rootApi.get('/admin/partners/search', {
        params: {
          page: 0,
          size: 999999
        }
      })
    );
    const listPartner = partners?.data.data || [];
    const partner_ops = listPartner.map((item) => {
      return {
        label: `${item?.name}`,
        value: item?.id
      };
    });
    return partner_ops;
  };

  const { data: dataUser, isLoading: loadingOperators } = getListOperators();
  const lstUserOperator = dataUser.map((user) => {
    return { label: String(user.username), value: user.id };
  });

  const { data: dataProvinces, isLoading: loadingProvinces } = getListProvinces();
  const provinceOptions = dataProvinces.map((pro) => {
    return { label: String(pro.name), value: String(pro.code) };
  });

  const partner_options = getPartnerOptions();

  const handleChangeProvince = async (_, opt) => {
    if (opt === undefined || opt === null) {
      setPro(null);
      setDistrictOptions([]);
    } else {
      setPro(opt);
      const data = await getDataByParentCode(opt.value);
      const tmp = data ? data.map((d) => ({ value: String(d.code), label: String(d.name) })) : [];
      setDistrictOptions(tmp);
    }
    setDist(null);
    setComm(null);
    setCommuneOptions([]);
  };

  const handleChangeDistrict = async (_, opt) => {
    if (opt === undefined || opt === null) {
      setDist(null);
      setCommuneOptions([]);
    } else {
      setDist(opt);
      const data = await getDataByParentCode(opt.value);
      const tmp = data ? data.map((d) => ({ value: String(d.code), label: String(d.name) })) : [];
      setCommuneOptions(tmp);
    }
    setComm(null);
  };

  const handleChangeCommune = (_, opt) => {
    if (opt === undefined || opt === null) {
      setComm(null);
    } else {
      setComm(opt);
    }
  };

  const icon = new Leaflet.Icon({
    iconUrl: '/assets/images/icon/location_evc.png',
    iconAnchor: [5, 45],
    popupAnchor: [10, -44],
    iconSize: new Leaflet.Point(45, 45)
  });
  const handleMapClick = (e, setFieldValue) => {
    const { lat, lng } = e.latlng;
    // setMarker([lat, lng]);
    setFieldValue('latitude', lat);
    setFieldValue('longitude', lng);
  };
  const MapEvents = ({ setFieldValue }) => {
    useMapEvents({
      click: (e) => handleMapClick(e, setFieldValue)
    });
    return null;
  };

  const RecenterAutomatically = ({ lat, lng }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([lat, lng]);
    }, [lat, lng]);
    return null;
  };

  const stationResponeTable = depot?.station_responses?.map((item) => ({
    ...item,
    name: item?.name,
    identifier: item?.identifier,
    depotId: depot?.id,
    model: item?.model,
    vendor: item?.vendor,
    serialNumber: item?.serialNumber,
    address: item?.name
  }));
  const userResponeTable = depot?.user_responses?.map((item) => ({
    name: item?.full_name,
    userName: item?.username,
    userId: item?.id,
    email: item?.email
  }));
  const {
    mutateAsync,
    isLoading: loadingSubmit,
    isSuccess
  } = useMutation('create-depot', (values) => {
    const payload = {
      depot_code: values?.depot_code ?? '',
      depot_name: values?.depot_name ?? '',
      charge_type: values?.charge_type ?? '',
      address: values?.address ?? '',
      province_code: values?.province ?? '',
      district_code: values?.district ?? '',
      commune_code: values?.commune ?? '',
      latitude: values?.latitude ?? '',
      longitude: values?.longitude ?? '',
      depot_pic_id: values?.depot_pic ?? '',
      depot_pic: lstUserOperator?.find((item) => item.value === values?.depot_pic)?.label ?? '',
      image_url: values?.image_url ?? '',
      partner_id: values?.partner_id ?? ''
    };

    if (!depot) {
      return rootApi.post('/admin/depots', payload);
    }
    return rootApi.put(`/admin/depots/update/${depot.depot_code}`, payload);
  });
  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const { file } = values;
      if (file === null || file === undefined) {
        mutateAsync(values)
          .then(() => {
            showSuccess({
              message: isNull(depot) ? 'Thêm trạm thành công' : 'Chỉnh sửa thông tin thành công'
            });
            backToList();
          })
          .catch((error) => {
            showError(error || 'Vui lòng liên hệ admin để được hỗ trợ');
          });
        return;
      }

      let user = loadFromLocalStorageObjectFromBase64(contantAuthentication.DATA_AUTH);
      if (!isEmpty(user)) {
        user = JSON.parse(user);
      }
      if (!['image/png', 'image/jpeg', 'image/jpg'].includes(file.type)) {
        return showError('Định dạng file upload không đúng. Vui lòng kiểm tra lại!');
      }
      const fileName = values?.file?.name;
      await axios
        .get(`${URL_API}/admin/depots/upload/image?file_name=${fileName}`, {
          headers: {
            Authorization: `Bearer ${user?.access_token}`
          }
        })
        .then(async (res) => {
          if (res.status !== 200) {
            return showError('Có lỗi xảy ra. Vui lòng thử lại!');
          }
          const urlUpload = res?.data.data;
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = async () => {
            const data = reader.result;
            const respone = await axios.put(urlUpload, data);
            if (respone.status !== 200) {
              showError('Upload hình ảnh trạm thành công');
              return;
            }

            const extractUrl = urlUpload.split('?');
            values['image_url'] = extractUrl[0];
            mutateAsync(values)
              .then(() => {
                showSuccess({
                  message: isNull(depot) ? 'Thêm trạm thành công' : 'Chỉnh sửa thông tin thành công'
                });
                backToList();
              })
              .catch((error) => {
                showError(error || 'Vui lòng liên hệ admin để được hỗ trợ');
              });
          };
        });
    } catch (e) {
      showError(e ?? 'Có lỗi xảy ra, vui lòng liên hệ ban quản trị!');
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = yup.object({
    depot_code: yup.string().required().label('ID Trạm sạc'),
    depot_name: yup.string().required().label('Tên trạm sạc'),
    address: yup.string().required().label('Địa chỉ'),
    depot_pic: yup.string().required().label('Người phụ trách'),
    province: yup.string().required().label('Tỉnh/Thành phố'),
    district: yup.string().required().label('Quận/Huyện'),
    commune: yup.string().required().label('Phường/Xã')
  });

  return (
    <Page>
      <TabContext value={valueTab}>
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary">
          <Tab value="detailDepot" label="Thông tin trạm sạc" />
          <Tab value="userTable" label="Danh sách khách hàng" />
          <Tab value="depotTable" label="Danh sách trụ" />
        </Tabs>
        <TabPanel value="detailDepot">
          <h3>Thông tin trạm sạc</h3>

          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              resetForm,
              isSubmitting
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={5}>
                    <Grid item md={8} sm={12}>
                      <Grid container spacing={1}>
                        <Grid item md={6} sm={12}>
                          <TextField
                            size="small"
                            fullWidth
                            type="text"
                            name="depot_code"
                            label={'Mã trạm (*)'}
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.depot_code}
                            onChange={handleChange}
                            helperText={touched?.depot_code && errors?.depot_code}
                            error={Boolean(errors?.depot_code && touched?.depot_code)}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="depot_name"
                            label={'Tên trạm (*)'}
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.depot_name}
                            onChange={handleChange}
                            helperText={touched?.depot_name && errors?.depot_name}
                            error={Boolean(errors?.depot_name && touched?.depot_name)}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} sx={{ mt: 2 }}>
                          <SelectBox
                            disabled={!adminRoles}
                            getOptionLabel={(option) => option.label || ''}
                            options={partner_options}
                            name="partner_id"
                            label={`Công ty - Đối tác`}
                            col={12}
                            size="small"
                            handleBlur={handleBlur}
                            value={
                              partner_options?.find((item) => item.value === values?.partner_id) ??
                              partner
                            }
                            onChange={(_, option) => {
                              setFieldValue('partner_id', option?.value);
                            }}
                            hasError={Boolean(errors?.partner_id && touched?.partner_id)}
                            errorText={touched?.partner_id && errors?.partner_id}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} sx={{ mt: 2 }}>
                          <SelectBox
                            required
                            loading={loadingProvinces}
                            getOptionLabel={(option) => option.label || ''}
                            options={provinceOptions}
                            name="province"
                            label={'Tỉnh/Thành phố'}
                            col={12}
                            size="small"
                            handleBlur={handleBlur}
                            value={
                              provinceOptions?.find((item) => item.value === values?.province) ??
                              pro
                            }
                            onChange={(_, option) => {
                              setFieldValue('province', option?.value);
                              handleChangeProvince(_, option);
                              if (!option) {
                                setFieldValue('province', '', false);
                                setFieldValue('district', '', false);
                                setFieldValue('commune', '', false);
                              }
                            }}
                            hasError={Boolean(errors?.province && touched?.province)}
                            errorText={touched?.province && errors?.province}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} sx={{ mt: 2 }}>
                          <SelectBox
                            required
                            getOptionLabel={(option) => option.label || ''}
                            options={districtOptions}
                            name="district"
                            label={'Quận/Huyện'}
                            col={12}
                            size="small"
                            handleBlur={handleBlur}
                            value={
                              districtOptions?.find((item) => item.value === values?.district) ??
                              dist
                            }
                            onChange={(_, option) => {
                              setFieldValue('district', option?.value);
                              handleChangeDistrict(_, option);
                              if (!option) {
                                setFieldValue('commune', '', false);
                              }
                            }}
                            hasError={Boolean(errors?.district && touched?.district)}
                            errorText={touched?.district && errors?.district}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} sx={{ mt: 2 }}>
                          <SelectBox
                            required
                            getOptionLabel={(option) => option.label || ''}
                            options={communeOptions}
                            name="commune"
                            label={'Phường/Xã'}
                            size="small"
                            col={12}
                            handleBlur={handleBlur}
                            value={
                              communeOptions?.find((item) => item.value === values?.commune) ?? comm
                            }
                            onChange={(_, option) => {
                              setFieldValue('commune', option?.value);
                              handleChangeCommune(_, option);
                            }}
                            hasError={Boolean(errors?.commune && touched?.commune)}
                            errorText={touched?.commune && errors?.commune}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} sx={{ mt: 2 }}>
                          <SelectBox
                            getOptionLabel={(option) => option.label || ''}
                            options={depotType}
                            name="charge_type"
                            label={'Loại trạm'}
                            size="small"
                            col={12}
                            handleBlur={handleBlur}
                            value={
                              depotType?.find((item) => item.value === values?.charge_type) ??
                              chargeType
                            }
                            onChange={(_, option) => {
                              setFieldValue('charge_type', option?.value);
                            }}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} sx={{ mt: 2 }}>
                          <SelectBox
                            required
                            getOptionLabel={(option) => option.label || ''}
                            options={lstUserOperator}
                            name="depot_pic"
                            label={'Chọn người vận hành'}
                            size="small"
                            col={12}
                            handleBlur={handleBlur}
                            value={
                              lstUserOperator?.find((item) => item.value === values?.depot_pic) ??
                              userOperator
                            }
                            onChange={(_, option) => {
                              setFieldValue('depot_pic', option?.value);
                            }}
                            hasError={Boolean(errors?.depot_pic && touched?.depot_pic)}
                            errorText={touched?.depot_pic && errors?.depot_pic}
                          />
                        </Grid>
                        <Grid item md={12} sm={12} sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="address"
                            label={'Địa chỉ'}
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.address}
                            onChange={handleChange}
                            helperText={touched?.address && errors?.address}
                            error={Boolean(errors?.address && touched?.address)}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <TextField
                            size="small"
                            fullWidth
                            type="text"
                            name="latitude"
                            label={'Vĩ độ'}
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.latitude}
                            onChange={handleChange}
                            helperText={touched?.latitude && errors?.latitude}
                            error={Boolean(errors?.latitude && touched?.latitude)}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        <Grid item md={6} sm={12}>
                          <TextField
                            fullWidth
                            size="small"
                            type="text"
                            name="longitude"
                            label={'Kinh độ'}
                            variant="outlined"
                            onBlur={handleBlur}
                            value={values.longitude}
                            onChange={handleChange}
                            helperText={touched?.longitude && errors?.longitude}
                            error={Boolean(errors?.longitude && touched?.longitude)}
                            sx={{ mt: 2 }}
                          />
                        </Grid>
                        <Grid item sm={12} xs={12} sx={{ width: '100%', height: 287, mt: 2 }}>
                          <LeafletMap zoom={13} center={[values?.latitude, values?.longitude]}>
                            <MapEvents setFieldValue={setFieldValue} />
                            <Marker
                              position={[values?.latitude, values?.longitude]}
                              icon={icon}></Marker>
                            <RecenterAutomatically lat={values?.latitude} lng={values?.longitude} />
                          </LeafletMap>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={4} sm={12}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography col={8} variant="subtitle2" color="primary">
                          <b>Hình ảnh trạm sạc</b>
                        </Typography>
                        {depot && !isNull(depot?.image_url) && (
                          <Typography col={4} variant="subtitle2" color="primary">
                            <Link
                              onClick={() => {
                                setIsNewImage(false);
                              }}>
                              Cập nhật ảnh
                            </Link>
                          </Typography>
                        )}
                      </div>

                      {depot && !isNull(depot?.image_url) && isNewImage ? (
                        <Grid item lg={12} xs={12}>
                          <Box
                            component="img"
                            sx={{
                              height: 300,
                              width: '100%',
                              maxHeight: { xs: 800, md: 600 },
                              maxWidth: { xs: 1000, md: 800 }
                            }}
                            alt=""
                            src={depot?.image_url}
                          />
                        </Grid>
                      ) : (
                        <ImageDropZone
                          handleChangeImage={(file, status) => {
                            if (status === 'removed') {
                              setFieldValue('file', '');
                            } else {
                              setFieldValue('file', file);
                            }
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <div
                        style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                        <LoadingButton
                          sx={{
                            color: '#FFFFFF',
                            fontSize: '14px',
                            fontWeight: 600,
                            bgcolor: '#2D9764',
                            '&:hover': {
                              bgcolor: '#2D9764'
                            }
                          }}
                          size="small"
                          type="submit"
                          loading={loading}
                          variant="contained">
                          {'Lưu thông tin'}
                        </LoadingButton>
                        <Button
                          size="small"
                          type="button"
                          variant="outlined"
                          sx={{
                            ml: 2,
                            color: '#18181B',
                            fontSize: '14px',
                            fontWeight: 600,
                            borderColor: '#2D9764',
                            '&:hover': {
                              borderColor: '#2D9764'
                            }
                          }}
                          onClick={() => {
                            resetForm();
                          }}>
                          Cài lại
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          </Formik>
        </TabPanel>

        <TabPanel value="userTable">
          {param?.depot_code && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => setOpenDialogBlindOwner(true)}
              startIcon={<CandlestickChartIcon />}>
              Gán khách hàng
            </Button>
          )}

          <UserTable
            items={userResponeTable || []}
            depotCode={depot?.depot_code}
            refetch={fetchDepotDetail}
          />
        </TabPanel>
        <TabPanel value="depotTable">
          <StationTable
            items={stationResponeTable || []}
            depotCode={depot?.depot_code}
            refetch={fetchDepotDetail}
          />
        </TabPanel>
      </TabContext>
      <ActionBar onBack={backToList} />
      <DialogBindOwner
        depot={depot}
        open={openDialogBlindOwner}
        setOpen={setOpenDialogBlindOwner}
        refetch={fetchDepotDetail}
      />
    </Page>
  );
};

export default CreateDepot;
