import React from 'react';
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { MODAL_MODE } from '@contants/index';
import { ArrowBack } from '@mui/icons-material';

const ActionBar = ({
  onBack,
  onCancel,
  mode,
  sx,
  children,
  elBefore,
  elAfter,
  startSession,
  disableSaveButton
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        py: 2,
        mb: -2,
        mt: 2,
        position: 'sticky',
        bottom: -24,
        zIndex: 100,
        background: '#fff',
        '& button + button': {
          ml: 4 / 3
        },
        ...sx
      })}>
      {children ? (
        children
      ) : (
        <>
          {elBefore}

          {typeof onBack === 'function' && (
            <Button
              sx={{
                color: '#FFFFFF',
                fontSize: '14px',
                fontWeight: 600,
                bgcolor: '#2D9764',
                '&:hover': {
                  bgcolor: '#2D9764'
                }
              }}
              onClick={onBack}
              startIcon={<ArrowBack />}>
              {t('actionBar.back')}
            </Button>
          )}

          {typeof onCancel === 'function' && (
            <Button variant="outlined" color="subText" onClick={onCancel}>
              {t('actionBar.cancel')}
            </Button>
          )}

          {mode === MODAL_MODE.CREATE && (
            <Button type="submit" icon="save" disabled={disableSaveButton}>
              {t('actionBar.create')}
            </Button>
          )}

          {mode === MODAL_MODE.UPDATE && (
            <Button type="submit" icon="save" disabled={disableSaveButton}>
              {t('actionBar.save')}
            </Button>
          )}

          {elAfter}

          {startSession}
        </>
      )}
    </Box>
  );
};

ActionBar.defaultProps = {
  children: null,
  elBefore: null,
  elAfter: null,
  sx: {},
  disableSaveButton: false
};

ActionBar.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.shape(),
  onBack: PropTypes.func,
  onCancel: PropTypes.func,
  mode: PropTypes.oneOf([MODAL_MODE.CREATE, MODAL_MODE.UPDATE]),
  elBefore: PropTypes.node,
  elAfter: PropTypes.node,
  disableSaveButton: PropTypes.bool
};

export default ActionBar;
